import $ from 'jquery';
import 'slick-carousel';

function flexible_col_slider_split() {
	$(document).ready(function () {
		$('.flexible-col-slider-split').each(function (index) {
			$('.gallery-imgs.slider', this).slick({
				autoplay: true,
				autoplayspeed: 800,
				dots: true,
				infinite: false,
				arrows: true,
				prevArrow:
					"<button class='slick-custom-arrow prev-arrow' aria=label='Previous'><svg width='27' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M19 10.9999L7.82999 10.9999L12.71 6.11991C13.1 5.72991 13.1 5.08991 12.71 4.69991C12.6175 4.60721 12.5076 4.53366 12.3866 4.48348C12.2656 4.4333 12.136 4.40747 12.005 4.40747C11.874 4.40747 11.7443 4.4333 11.6234 4.48348C11.5024 4.53366 11.3925 4.60721 11.3 4.69991L4.70999 11.2899C4.61728 11.3824 4.54373 11.4923 4.49355 11.6133C4.44337 11.7343 4.41754 11.8639 4.41754 11.9949C4.41754 12.1259 4.44337 12.2556 4.49355 12.3765C4.54373 12.4975 4.61728 12.6074 4.70999 12.6999L11.3 19.2899C11.3926 19.3825 11.5025 19.4559 11.6234 19.506C11.7444 19.5561 11.8741 19.5819 12.005 19.5819C12.1359 19.5819 12.2656 19.5561 12.3865 19.506C12.5075 19.4559 12.6174 19.3825 12.71 19.2899C12.8026 19.1973 12.876 19.0874 12.9261 18.9665C12.9762 18.8455 13.002 18.7158 13.002 18.5849C13.002 18.454 12.9762 18.3243 12.9261 18.2034C12.876 18.0824 12.8026 17.9725 12.71 17.8799L7.82999 12.9999L19 12.9999C19.55 12.9999 20 12.5499 20 11.9999C20 11.4499 19.55 10.9999 19 10.9999Z' fill='white'/></svg></button>",
				nextArrow:
					"<button class='slick-custom-arrow next-arrow' aria=label='Next'><svg width='27' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M19 10.9999L7.82999 10.9999L12.71 6.11991C13.1 5.72991 13.1 5.08991 12.71 4.69991C12.6175 4.60721 12.5076 4.53366 12.3866 4.48348C12.2656 4.4333 12.136 4.40747 12.005 4.40747C11.874 4.40747 11.7443 4.4333 11.6234 4.48348C11.5024 4.53366 11.3925 4.60721 11.3 4.69991L4.70999 11.2899C4.61728 11.3824 4.54373 11.4923 4.49355 11.6133C4.44337 11.7343 4.41754 11.8639 4.41754 11.9949C4.41754 12.1259 4.44337 12.2556 4.49355 12.3765C4.54373 12.4975 4.61728 12.6074 4.70999 12.6999L11.3 19.2899C11.3926 19.3825 11.5025 19.4559 11.6234 19.506C11.7444 19.5561 11.8741 19.5819 12.005 19.5819C12.1359 19.5819 12.2656 19.5561 12.3865 19.506C12.5075 19.4559 12.6174 19.3825 12.71 19.2899C12.8026 19.1973 12.876 19.0874 12.9261 18.9665C12.9762 18.8455 13.002 18.7158 13.002 18.5849C13.002 18.454 12.9762 18.3243 12.9261 18.2034C12.876 18.0824 12.8026 17.9725 12.71 17.8799L7.82999 12.9999L19 12.9999C19.55 12.9999 20 12.5499 20 11.9999C20 11.4499 19.55 10.9999 19 10.9999Z' fill='white'/></svg></button>",
				slidesToShow: 1,
				slidesToScroll: 1,
				pauseOnHover: true,
			});

			$('.prev-btn', this).click(function () {
				$('.gallery-imgs.slider', this).slick('slickPrev');
			});

			$('.next-btn', this).click(function () {
				$('.gallery-imgs.slider', this).slick('slickNext');
			});
		});
	});
}

export default flexible_col_slider_split;
