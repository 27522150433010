import $ from 'jquery';

function panels() {
	$('.panels .panel .panel-control').on('click', function () {
		if ($(this).closest('.panel').hasClass('active')) {
			// Let accordion remain open
		} else {
			$('.panels .panel').each(function () {
				$(this).removeClass('active');
			});

			$(this).closest('.panel').addClass('active');
		}
	});

	$('.panels .panel-inner .close-btn').on('click', function () {
		var $target = $(this).closest('.panel');

		$target.removeClass('active');
	});
}

export default panels;
