import $ from 'jquery';
import 'slick-carousel';

function three_col_slider() {
	$(document).ready(function () {
		function initSlider() {
			$('.three-grid .slider-wrap')
				.not('.slick-initialized')
				.slick({
					infinite: true,
					slidesToShow: 1,
					slidestoScroll: 1,
					dots: true,
					mobileFirst: true,
					responsive: [
						{
							breakpoint: 1024,
							settings: 'unslick',
						},
					],
				});
		}

		$(window).on('resize', initSlider);

		initSlider();
	});
}

export default three_col_slider;
