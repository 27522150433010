import $ from 'jquery';

function general() {
	$(document).ready(function () {
		// Burger Menu

		var header = $('#header'),
			navButton = $('#header .menu-toggle'),
			nav = $('#primary-navigation-mobile');

		navButton.click(function () {
			navButton.toggleClass('open');

			if (navButton.hasClass('open')) {
				header.addClass('open');
				setTimeout(function () {
					nav.addClass('open-active');
				}, 150);
			} else {
				nav.removeClass('open-active');
				setTimeout(function () {
					header.removeClass('open');
				}, 300);
			}
		});

		// Prepaing the Sub Menus

		$('#primary-navigation-mobile .has-submenu > a').append(
			"<span class='menu-indicator'></span>"
		);

		$('#primary-navigation-desktop .has-submenu > a').append(
			"<span class='menu-indicator-b'><svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M6.32276 6.31994C6.10635 6.53608 5.813 6.65749 5.50713 6.65749C5.20127 6.65749 4.90792 6.53608 4.6915 6.31994L0.337891 1.96786C0.121471 1.75134 -7.21189e-05 1.45771 3.21046e-08 1.15158C7.21831e-05 0.84544 0.121753 0.551871 0.338276 0.33545C0.554798 0.11903 0.848425 -0.00251353 1.15456 -0.00244137C1.4607 -0.00236922 1.75427 0.119312 1.97069 0.335835L5.50713 3.87228L9.04358 0.335835C9.26116 0.125484 9.55266 0.00900916 9.85529 0.0114953C10.1579 0.0139813 10.4475 0.13523 10.6616 0.349126C10.8757 0.563023 10.9972 0.852453 11 1.15508C11.0027 1.4577 10.8865 1.74931 10.6764 1.96709L6.32353 6.32071L6.32276 6.31994Z' fill='white'/></svg></span>"
		);

		// Sub Menus

		$('.has-submenu > a').on('click', function (e) {
			e.preventDefault();

			var targetParent = $(this).closest('.has-submenu'),
				targetSub = targetParent.find('.sub-menu'),
				otherItems = $('#menu-main-navigation > li').not(targetParent);

			if (targetParent.hasClass('open')) {
				targetParent.removeClass('open');
				otherItems.removeClass('faded');
				targetSub.slideUp();
			} else {
				otherItems.find('.sub-menu').slideUp();
				targetParent.removeClass('faded');
				otherItems.addClass('faded');
				otherItems.removeClass('open');
				targetParent.addClass('open');
				targetSub.slideDown();
			}
		});

		// Scroll function to detect if elements with [data-scroll-show] are in view
		// Adding a value to data-scroll-show value will determine at what point the element will trigger

		function scrollShow() {
			$('[data-scroll-show]').each(function () {
				var slideInAt =
					window.scrollY + window.innerHeight - this.offsetHeight / 2;

				var imageBottom = this.offsetTop + this.offsetHeight;
				var isHalfShown = slideInAt > this.offsetTop;
				var isNotScrolledPast = window.scrollY < imageBottom;

				if (isHalfShown && isNotScrolledPast) {
					this.classList.add('show');
				} else {
					this.classList.remove('show');
				}
			});
		}

		var cachedWidth = $(window).width();

		$(window).resize(function () {
			var newWidth = $(window).width();

			if (newWidth !== cachedWidth) {
				cachedWidth = newWidth;
			}
		});

		$(window).on('resize', function () {
			scrollShow();
		});

		$(window).scroll(function () {
			scrollShow();
		});

		scrollShow();
	});
}

export default general;
