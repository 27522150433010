import $ from 'jquery';

function logo_animation() {
	$(document).ready(function () {
		$(window).scroll(function () {
			if (this.pageYOffset < 100) {
				$('#logo').removeClass('active');
			} else {
				$('#logo').addClass('active');
			}
		});
	});
}

export default logo_animation;
