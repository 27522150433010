import $ from 'jquery';

function announcement_bar() {
	$(document).ready(function () {
		var target = document.querySelector('body');
		var announcement = document.querySelector('.announcement-bar');
		var closeBtn = announcement.querySelector('.close');

		function updateBar() {
			console.log(sessionStorage.getItem('announcement'));
			if (
				sessionStorage.getItem('announcement') &&
				sessionStorage.getItem('announcement') === 'closed'
			) {
				target.classList.remove('announcement');
				console.log(target);
			} else {
				target.classList.add('announcement');
			}
		}

		function closeBar() {
			sessionStorage.setItem('announcement', 'closed');
			updateBar();
		}

		closeBtn.addEventListener('click', closeBar);

		updateBar();
	});
}

export default announcement_bar;
