import $ from 'jquery';

function flexible_accordion() {
	//Flexible Tabs

	//opening the first tab by default
	$('.accordion-container .tab-header').each(function (i, tabs) {
		if (i === 0) {
			$(this).addClass('open');
			$(this).next('.tab-content').slideDown();
		}
	});

	$('.accordion-container').on('click', '.tab-header', function () {
		if ($(this).hasClass('open')) {
			$(this).removeClass('open');
			$(this).next('.tab-content').slideUp();
		} else {
			$('.accordion-container .tab-header').removeClass('open');
			$('.accordion-container .tab-content').slideUp();

			var $tab = $(this);
			var $content = $tab.next();

			$tab.addClass('open');
			$content.slideToggle();
		}
	});
}

export default flexible_accordion;
