function flexible_testimonials() {
	document.addEventListener('DOMContentLoaded', function () {
		const mobileBreakpoint = 768; // Define your mobile breakpoint

		const testimonialSections = document.querySelectorAll(
			'#flexible-testimonials'
		);

		// Initialize the testimonials function for each section
		testimonialSections.forEach((section) => {
			const tabs = section.querySelectorAll(
				'.flexible-testimonials--tabs .tab'
			);
			const slidesContainer = section.querySelector(
				'.flexible-testimonials--slides--container'
			);
			const slides = section.querySelectorAll(
				'.flexible-testimonials--slides--container .slide'
			);

			// Function to remove 'active' class from all tabs and slides within the specific section
			function removeActiveClasses() {
				tabs.forEach((tab) => tab.classList.remove('active'));
				slides.forEach((slide) => slide.classList.remove('active'));
			}

			// Set first tab and slide as active on page load
			if (tabs.length > 0 && slides.length > 0) {
				tabs[0].classList.add('active');
				slides[0].classList.add('active');
			}

			// Add click event listener to each tab
			tabs.forEach((tab, index) => {
				tab.addEventListener('click', function () {
					// Remove 'active' class from all tabs and slides in this section
					removeActiveClasses();

					// Add 'active' class to the clicked tab and corresponding slide in this section
					tab.classList.add('active');
					slides[index].classList.add('active');
				});
			});

			// Function to initialize Slick slider
			function initializeSlick() {
				$(slidesContainer).slick({
					dots: true,
					arrows: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					adaptiveHeight: false,
				});
			}

			// Function to destroy Slick slider
			function destroySlick() {
				if ($(slidesContainer).hasClass('slick-initialized')) {
					$(slidesContainer).slick('unslick');
				}
			}

			// Function to check window size and initialize/destroy slider based on breakpoint
			function handleResponsiveSlider() {
				const windowWidth = window.innerWidth;

				if (windowWidth <= mobileBreakpoint) {
					// If below the mobile breakpoint, initialize Slick slider
					initializeSlick();
				} else {
					// If above the mobile breakpoint, destroy the slider
					destroySlick();
				}
			}

			// Initial check on load
			handleResponsiveSlider();

			// Re-check on window resize
			window.addEventListener('resize', handleResponsiveSlider);
		});
	});
}

export default flexible_testimonials;
