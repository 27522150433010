function flexible_card_links() {
	document.addEventListener('DOMContentLoaded', function () {
		const mobileBreakpoint = 768; // Define your mobile breakpoint
		const cardsContainer = document.querySelector(
			'.flexible-card-links--cards'
		);

		// Function to initialize Slick slider
		function initializeSlick() {
			$(cardsContainer).slick({
				dots: true,
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				adaptiveHeight: false,
				mobileFirst: true,
			});
		}

		// Function to destroy Slick slider
		function destroySlick() {
			if ($(cardsContainer).hasClass('slick-initialized')) {
				$(cardsContainer).slick('unslick');
			}
		}

		// Function to check window size and initialize/destroy slider based on breakpoint
		function handleResponsiveSlider() {
			const windowWidth = window.innerWidth;

			if (windowWidth <= mobileBreakpoint) {
				// If below the mobile breakpoint, initialize Slick slider
				initializeSlick();
			} else {
				// If above the mobile breakpoint, destroy the slider
				destroySlick();
			}
		}

		// Initial check on load
		handleResponsiveSlider();

		// Re-check on window resize
		window.addEventListener('resize', handleResponsiveSlider);
	});
}

export default flexible_card_links;
