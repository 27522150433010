function playbtn() {
	document.addEventListener('DOMContentLoaded', function () {
		var paths = document.querySelectorAll('.playbtn-svg');

		function processElement(el) {
			if (el !== null && el instanceof SVGGeometryElement) {
				// Check if the element is visible/rendered before processing
				if (el.getBBox().width > 0 && el.getBBox().height > 0) {
					try {
						var length =
							el.getTotalLength() > 0
								? el.getTotalLength() + 1
								: el.r.baseVal.value * 2 * Math.PI;

						el.style.strokeDashoffset = length;
						el.style.strokeDasharray = length;
					} catch (e) {
						console.error('Error in processing element', el, e);
					}
				} else {
					console.warn('Element is not visible or rendered', el);
				}
			} else {
				console.warn('Element is not an instance of SVGGeometryElement', el);
			}
		}

		if (paths) {
			paths.forEach(function (el) {
				processElement(el);
			});
		}

		// MutationObserver to handle dynamically added elements
		var observer = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				if (mutation.addedNodes.length > 0) {
					mutation.addedNodes.forEach(function (node) {
						if (node.nodeType === 1 && node.classList.contains('playbtn-svg')) {
							processElement(node);
						}
					});
				}
			});
		});

		// Start observing the document for added elements
		observer.observe(document.body, { childList: true, subtree: true });
	});
}

export default playbtn;
