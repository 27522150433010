import $ from 'jquery';
import 'slick-carousel';

function timeline_slider() {
	$(document).ready(function () {
		var slidesToShow = 9;

		$('.timeline-container').each(function () {
			// Clone the slides.
			var $slides = $('.milestone');
			var $details = $('.milestone-info');

			if ($slides.length > 1 && $slides.length <= slidesToShow) {
				var $slide;
				$slides.each(function () {
					$slide = $(this)
						.clone(true)
						.insertAfter($slide || $slides.last())
						.addClass('slick-cloned-2')
						.attr('id', '');
				});
				var $detail;
				$details.each(function () {
					$detail = $(this)
						.clone(true)
						.insertAfter($detail || $details.last())
						.addClass('slick-cloned-2')
						.attr('id', '');
				});
			}

			slidesToShow = Math.min(slidesToShow, $slides.length);

			$('.timeline').slick({
				centerMode: true,
				centerPadding: '0px',
				swipeToSlide: true,
				infinite: true,
				arrows: false,
				focusOnSelect: true,
				slidestoScroll: 1,
				slidesToShow: slidesToShow,
				asNavFor: '.timeline-info',
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 5,
						},
					},
				],
			});

			$('.timeline-info').slick({
				infinite: true,
				arrows: false,
				fade: true,
				draggable: false,
				slidestoScroll: 1,
				asNavFor: '.timeline',
			});
		});
	});
}

export default timeline_slider;
