import $ from 'jquery';
import 'slick-carousel';

function team_roster() {
	//Flexible Team Roster
	$(document).ready(function () {
		$('.team-roster-container .tab-header').each(function (i, tabs) {
			if (i === 0 && $(window).width() >= 768) {
				$(this).addClass('open');
				$(this).next('.tab-content').slideDown();
			} else {
				$(this).next('.tab-content').slideUp();
			}
		});

		$('.team-roster-container').on('click', '.tab-header', function () {
			if ($(this).hasClass('open')) {
				$(this).removeClass('open');
				$(this).next('.tab-content').slideUp();
			} else {
				$('team-roster-container .tab-header').removeClass('open');
				$('team-roster-container .tab-content').slideUp();

				var $tab = $(this);
				var $content = $tab.next();

				$tab.addClass('open');
				$content.slideToggle();

				$content.find('.slick-initialized').slick('setPosition');
			}
		});

		function initSlider() {
			$('.team-roster-container .team-grid')
				.not('.slick-initialized')
				.slick({
					infinite: false,
					slidesToShow: 1,
					slidestoScroll: 1,
					centerMode: false,
					dots: false,
					arrows: true,
					prevArrow:
						"<button class='slick-custom-arrow prev-arrow' aria=label='Previous'><i class='fas fa-chevron-left'></i></button>",
					nextArrow:
						"<button class='slick-custom-arrow next-arrow' aria=label='Next'><i class='fas fa-chevron-right'></i></button>",
					mobileFirst: true,
					responsive: [
						{
							breakpoint: 768,
							settings: 'unslick',
						},
					],
				});
		}

		$(window).on('resize', initSlider);

		initSlider();
	});

	const roster = $('.team-member');

	function openBio() {
		let selected = $(this).closest('.team-member');
		let overlay = selected.find('.team-bio-overlay');
		let bio = selected.find('.team-bio');

		selected.addClass('open');
		overlay.addClass('open');
		bio.addClass('open');

		sizeBio(bio);

		selected
			.find('.bio-btn')[0]
			.addEventListener('click', closeBio, { once: true });
	}

	function closeBio() {
		let selected = $(this).closest('.team-member');
		let overlay = selected.find('.team-bio-overlay');
		let bio = selected.find('.team-bio');

		selected.removeClass('open');
		overlay.removeClass('open');
		bio.removeClass('open');
	}

	function sizeBio(bio) {
		if (bio != null) {
			let w = window.innerWidth;
			let h = window.innerHeight;
			let axis = w >= h ? h : w;

			bio.height(axis * 0.85) + 'px';
			bio.width(axis * 0.85) + 'px';
		}
	}

	$(window).on('resize', function () {
		sizeBio($('.team-bio.open'));

		//Close all bios on resize
		setBioEvents();
	});

	function setBioEvents() {
		roster.each(function () {
			const open = this.querySelector('.bio-btn');
			const close = this.querySelector('.close');
			const bio = this.querySelector('.team-bio');
			const overlay = this.querySelector('.team-bio-overlay');

			//close any open bios
			if (bio != null) {
				bio.classList.remove('open');
				overlay.classList.remove('open');
			}

			if (open != null) {
				open.addEventListener('click', openBio);
			}

			if (close != null) {
				close.addEventListener('click', closeBio);
			}
		});
	}

	setBioEvents();
}

export default team_roster;
