import $ from 'jquery';
import 'slick-carousel';

function gallery_slider() {
	$(document).ready(function () {
		$('.gallery').each(function (index) {
			var $currentSlide = $('.pagination .current', this);
			var $totalSlides = $('.pagination .total', this);

			$('.gallery-imgs.slider', this).on(
				'init reInit',
				function (event, slick, currentSlide, nextSlide) {
					var i = (currentSlide ? currentSlide : 0) + 1;
					$currentSlide.text(i);
					$totalSlides.text('/' + slick.slideCount);
				}
			);

			$('.gallery-imgs.slider', this).on(
				'afterChange',
				function (event, slick, currentSlide, nextSlide) {
					var i = (currentSlide ? currentSlide : 0) + 1;
					$currentSlide.text(i);
				}
			);

			$('.gallery-imgs.slider', this).slick({
				asNavFor: $('.gallery-caption .slider', this),
				autoplay: true,
				autoplayspeed: 800,
				dots: true,
				infinite: false,
				arrows: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				pauseOnHover: true,
			});

			$('.gallery-caption .slider', this).slick({
				asNavFor: $('.gallery-imgs.slider', this),
				fade: true,
				arrows: false,
				cssEase: 'ease-in-out',
				pauseOnHover: true,
			});
		});
	});
}

export default gallery_slider;
