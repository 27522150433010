import flexible_col_slider_split from './components/flexible-col-slider-split.js';
import case_study_slider from './components/case-study-slider.js';
import flexible_accordion from './components/flexible-accordion.js';
import logo_animation from './components/logo-animation.js';
import tabs from './components/tabs.js';
import playbtn from './components/playbtn.js';
import timeline_slider from './components/timeline-slider.js';
import panels from './components/panels.js';
import general from './components/general.js';
import three_col_slider from './components/3-col-slider.js';
import video from './components/video.js';
import gallery_slider from './components/gallery-slider.js';
import announcement_bar from './components/announcement-bar.js';
import team_roster from './components/team-roster.js';
import accordion from './components/accordion.js';
import flexible_testimonials from './components/flexible-testimonials.js';
import flexible_card_links from './components/flexible-card-links.js';

flexible_col_slider_split();
case_study_slider();
flexible_accordion();
logo_animation();
tabs();
playbtn();
timeline_slider();
panels();
general();
three_col_slider();
video();
gallery_slider();
announcement_bar();
team_roster();
accordion();
flexible_testimonials();
flexible_card_links();
